.asc-photobooth {
    margin-top: 5%;
    background: transparent linear-gradient(321deg, #ffffff 0%, #ffffffd2 48%, #ffffffa0 100%) 0% 0% no-repeat padding-box;
    border: 2px solid #ffffff;
    border-radius: 16px;
    opacity: 0.87;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    width: 466px;
    height: 578px;
}

.asc-video {
    position: absolute;
    border-radius: 50%;
    object-fit: cover;
    border: white 5px solid;
    padding: 0.25rem;
    margin-bottom: 30%;
}

.asc-framename {
    position: absolute;
    color: white;
    font-weight: bolder;
    text-transform: capitalize;
    margin-top: 38%;
}

.asc-mainframe {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.asc-photobooth-button-container {
    margin-top: 5%;
}

.asc-photobooth-button {
    background: #e60000 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    text-align: center;
    font: normal normal normal 24px/28px Vodafone Rg;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    width: 208px;
}

.summit-frame {
    height: 12.5rem;
    width: 10rem;
}

.summit-bg {
    height: 27.5rem;
    width: 22.5rem;
    border-radius: 8px;
}

.summit-video {
    position: absolute;
    border-radius: 50%;
    object-fit: cover;
    border: white 5px solid;
    padding: 0.25rem;
    margin-bottom: 30%;
    width: 250px;
    height: 250px;
}

.summit-download-btn,
.summit-download-btn:hover {
    background: transparent linear-gradient(99deg, #B10000 0%, var(--unnamed-color-e60000) 100%) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-e60000);
    background: transparent linear-gradient(99deg, #B10000 0%, #E60000 100%) 0% 0% no-repeat padding-box;
    text-decoration: none;
    border-radius: 8px;
    opacity: 1;
    padding: 0.5rem 1rem;
    margin-right: 2.5rem;
    display: inline;
    align-items: center;
    text-align: center;
    font: normal normal normal 16px/18px Vodafone Rg;
    letter-spacing: 0.16px;
    color: white;
    opacity: 1;
    margin-left: 1rem;
    cursor: pointer;
}

.btn-back-envirtual-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

}

.ddl {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.btn-styling {
    margin-top: 2rem;
    color: #FFF;
    width: 253px;
    height: 60px;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border-radius: 6px;
    background: var(--Vodafone-red, #E60000);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;

}

.btn-styling:hover:enabled {
    background-color: #565555;
    text-shadow: none;
    color: white !important;
    text-decoration: none !important;
}

.btn-styling2 {
    margin-top: 2rem;
    color: #E60000;
    width: 253px;
    height: 60px;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border-radius: 6px;
    border: #E60000 2px solid;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.btn-styling2:hover {
    background-color: #565555;
    text-shadow: none;
    color: white !important;
    text-decoration: none !important;
}