.messageBox {
    background: #F3F3F3;
    border-radius: 8px;
    padding: 0.75rem;
    color: white;
    display: inline-block;
    max-width: 100%;
        height: 100%;
}

.messageTextright {
    text-align: left;
    width: 100%;
    letter-spacing: 0;
    float: left;
font-size: 1.25vw;
    word-wrap: break-word;
    margin: 0;
}
.messageTextleft {
    text-align: left;
    width: 100%;
    letter-spacing: 0;
    float: left;
    font-size: 1.25vw;
    word-wrap: break-word;
    margin: 0;
}
.messageText img {
    vertical-align: middle;
}

.messageContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 5%;
    margin-top: 3px;
}

.sentText {
    display: flex;
    align-items: center;
    font-family: Helvetica;
    color: black;
    letter-spacing: 0.3px;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.justifyStart {
    justify-content: flex-start;
}

.justifyEnd {
    justify-content: flex-end;
}

.colorWhite {
    color: white;
}

.colorDark {
color: var(--Vodafone-Black, #25282B);
}
.colorDark-name {
    color: var(--Vodafone-Black, #25282B);
    font-weight:600 ;
}
.backgroundBlue {
    background-color: var(--Vodafone-Grey, #4A4D4E);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        color: #FFF;
        
            text-align: right;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.255px;
}

.backgroundLight {
background-color: #F7F7F7;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: #FFF;

    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.255px;
}
.double-tick{
 text-align: right;
}
.circle {
    background-color: #E6E6E6;
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 50%;
    text-transform: uppercase;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #000;

    font-size: 2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.online {
    width: 1vw;
    height: .5vw;
    position: relative;
    bottom: 75%;
    left: 10%;
}

.dropdown-toggle{
    background-color: transparent;
    border: none;
    color: #e60000;
}
.more-ddl-icon{
    width: 1rem;
        height: 1rem;
        margin: 0.5rem;
}
.emojis {
    position: absolute;
    /* margin-bottom: 5rem; */
    background-color: transparent;
    border: none;

}

.emoji-img {
    width: 1.5rem;
    height: 1.5rem;
}
.reactions-messageBox-left{
    position: relative;
    z-index: 500;
        background: #F3F3F3;
        border-radius: 8px;
        padding: 0.75rem;
        color: white;
        display: inline-block;
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        background-color: #F7F7F7;
        align-items: center;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        color: #FFF;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.255px;
        background-color: var(--Vodafone-Grey, #4A4D4E);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            color: #FFF;
        
            text-align: right;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.255px;
        bottom: 10%;
       
}
.reaction-number-left{
    color: #E6E6E6;
    font-weight: 700;
        margin-right: 0.5rem;
}
.reaction-emoji{
    height: 1.5rem;
    width: 1.5rem;
}
.reaction-div{
    margin-left: 0.5rem;
}
.reactions-messageBox-right {
position: relative;
    z-index: 500;
    background: #F3F3F3;
    border-radius: 8px;
    padding: 0.75rem;
    color: white;
    display: inline-block;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: #F7F7F7;
    align-items: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: #FFF;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.255px;
    bottom: 10%;
    

}

.reaction-number-right {
    color: #25282B;
    font-weight: 700;
    margin-right: 0.5rem;
}
.message-full{
    display: flex;
    flex-direction: column;
        height: 100%;
}
.emojis-div{
   position: relative;
   right: 24%;
   bottom: 10%;
   z-index: 100000;
   height: 2rem;
       
}
@media only screen and (orientation: portrait) {.messageTextleft{
    font-size: 4vw;
}.messageTextright {
    font-size: 4vw;
}.circle {
    background-color: #E6E6E6;
    width: 4.5vw;
    height: 4.5vw;
    border-radius: 50%;
    text-transform: uppercase;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #000;
    text-align: center;
    font-size: 4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.online {
    width: 4.9px;
    height: 5.1px;
    bottom: 77%;
        left: 12%;
}.emojis{left: 48%;}
.emojis-div {
    position: absolute;
        right: 50%;
        bottom: 10%;
        z-index: 100000;
        height: 2rem;
        max-width: 68vw;
}}