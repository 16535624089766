.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.summit-joinOuterContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-image: url("/src/assets/summit-bg.png");

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 132%;
  /* object-fit: cover; */
  min-height: 100%;
  min-width: 100vw;

  /* Set up proportionate scaling */
  /* width: 100%;
  height: auto; */

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}
.wiv-popup{
  background-image: url("./assets/wiv/popup.png");
  background-repeat: no-repeat;
  background-size: 100% 109%;
  height: 100%;
  max-width: 100%;
  background-color: transparent !important;
  border: none !important;
}

@media only screen and (max-width: 991.98px) {

 #wivamobile{
  position: absolute !important;
    z-index: 10002 !important;
 }

  #wivawelcome {
    position: absolute !important;
    z-index: 10001 !important;
  }
}