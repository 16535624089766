.asc3-joinOuterContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("/src/assets/asc3-loading.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* object-fit: cover; */
    min-height: 100%;
    min-width: 100vw;
position: absolute;
z-index: 1000000;
    
    top: 0;
    left: 0;
}.toggle-asc-sound {
    position: absolute;
    top: 5%;
    left: 4rem;
    /* background-color: black; */
    /* width: 5%; */

    text-align: center;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    z-index: 99;
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    box-shadow: 0 0 0 0 #f06292;
}

.welcome-text{
margin-top: 5rem;
  
    font-family: 'Polaris' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
  
    color: #FFFFFF;
}
.Loading-title{

  
    font-family: 'Polaris' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height */
  
  
    color: #FFFFFF;
}
.first-section{
 
      align-items: "center";
      justify-content: "center";
      flex-direction: "column";
      border: 2px solid #FCFCFC;
        box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2.5px);
        /* Note: backdrop-filter has minimal browser support */
      
        border-radius: 10px;
     
        padding: 3rem 1rem;
        margin: 2rem 0;
        flex-direction: column;
      display: flex;
        justify-content: center;
        align-items: center;
        min-width: 28rem
}
.other-section {
  display: none;
  align-items: "center";
  justify-content: "center";
  flex-direction: "column";
  border: 2px solid #FCFCFC;
  box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2.5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;

  padding: 3rem 1rem;
  margin: 2rem 0;
 
}
.instruction{
font-family: 'Vodafone Rg';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  color: #FCFCFC;
  margin: 0.5rem;
}
.img{
  z-index: 9999;
  width: 15rem;
  
}

.footer{
  position: "absolute";
      bottom: "7.5rem";
      display: "flex";
      flex-direction: "column";
      align-items: "center";
      justify-content: "center"
}
.for-better{
  font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */
  
     display: block;
    color: #FFFFFF;
}