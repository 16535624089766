.navbar-wiv{
background: #FFF;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
            position: relative;
            padding: .5rem 0;
         height: 10vh;
               
               
}
.nav-bar-img{
    margin: 1rem;
   
    color: var(--Vodafone-red, #E60000);
    
    font-size: 1.5vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-right: 1.5rem;
        text-transform: capitalize;
      
}.nav-bar-img-bold{
color: var(--Vodafone-red, #E60000);

    font-size: 1.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
    text-transform: capitalize;
}
.nav-bar-hello{
    color: var(--Vodafone-red, #E60000);
    
        font-size: 1.5vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-right: 1.5rem;
        text-transform: capitalize;
}
.drop-down-btn,
.drop-down-btn:hover,.drop-down-btn:focus {
    box-shadow:none;
margin-top: 20px;
    background-color: transparent;
    color: var(--Vodafone-red, #E60000);
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* padding-right: 1.5rem; */
    text-transform: capitalize;
    flex-shrink: 0;
    color: #fff;
    text-align: center;
    border: none;
    border-radius: 8px;
    position: absolute;
    right: 1rem;
    z-index: 5000;
    /* text-align: left; */
    text-decoration: underline;
  
    letter-spacing: 0.13px;
    color: #635f5f;
    opacity: 1;
}

.joinroom{
    position: absolute;
}
.joinroom-img{
    width: 100%;
    position: relative;
}
.joinroom-text-div{
position: absolute;
    top: 0;
    z-index:5;
    height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.joinroom-text{
text-align: left;
    padding: 2vh 0 0 6vw;
    margin: 0.5vh 0 0 0 ;
    letter-spacing: 0.13px;
    color: #FFFFFF;
    opacity: 1;
}
.joinroom-btn,
.joinroom-btn:hover,
.joinroom-btn:focus {
    border: none;
    background-color: transparent;
    box-shadow: none;
text-align: left;
    text-decoration: underline;
    font: normal normal normal 25px/30px ;
    letter-spacing: 0.13px;
    color: #FFFFFF;
    opacity: 1;
}
.nps-survey{
    display: block;
    position:absolute;
    z-index: 100000;
    bottom:10%;
    right: 1rem;
background: #FFFFFF 0% 0% no-repeat padding-box;
    border:1px solid #26282B;
    border-radius: 22px 22px 22px 22px;
    width: 50%;
    height: fit-content;
    
    
}
.nps-suevey-header{
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem ;
    height: 25%;
    background: #26282B 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 22px 22px 0px 0px;
        align-items: center;
        justify-content: space-between;
}
.header-text{
    text-align: left;
        font: normal normal normal 32px/37px Vodafone Lt;
        letter-spacing: 0px;
        color: #FFFFFF;
}
.skip-for-now{
    text-align: right;
        font: normal normal normal 24px/28px Vodafone Lt;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        cursor: pointer;
}
.nps-survey-body{
    display: flex;
        flex-direction: column;
        padding: 0.5rem 1rem;
    
      
        
        justify-content: space-between;
}
.nps-survey-body-text{
    margin-top: 1rem;
text-align: left;
    font: normal normal normal 32px/37px Vodafone Lt;
    letter-spacing: 0px;
    color: #26282B;
    opacity: 1;
}
.survey-value-div{
        margin-top: 1rem;
    border: 1px solid #D6D6D6;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.survey-value{
        width: 10%;
    text-align: center;
        font: normal normal normal 32px/37px Vodafone Lt;
        letter-spacing: 0px;
        color: #26282B;
        border-right: 1px solid #D6D6D6;
cursor: pointer;
   
}
.survey-value-last {
        width: 10%;
    text-align: center;
    font: normal normal normal 32px/37px Vodafone Lt;
    letter-spacing: 0px;
    color: #26282B;
border-right:none;
cursor: pointer;

}
.survey-value-selected {
    width: 10%;
    text-align: center;
    font: normal normal normal 32px/37px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    border-right: 1px solid #D6D6D6;
    background-color: #E60000;
cursor: pointer;

}

.survey-value-last-selected {
    width: 10%;
    text-align: center;
    font: normal normal normal 32px/37px Vodafone Lt;
    letter-spacing: 0px;
color: #FFFFFF;
    background-color: #E60000;
    cursor: pointer;


}
.survey-desc{
        margin-top: 1rem;
    display:  flex;
    justify-content: space-between;
    flex-direction: row;
}
.survey-desc-text{
    text-align: right;
        font: normal normal normal 16px/18px Vodafone Lt;
        letter-spacing: 0px;
        color: #26282B;
}
.survey-submit-button{
        align-self: flex-end;
    display: flex;
    justify-items: flex-end;
        margin-top: 1rem;
        margin-bottom: 16px;
    background-color: #e60000;
   width:10%;
  padding: 0.5rem;
        flex-shrink: 0;
        color: #FFF;
    
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.255px;
        border: none;
        border-radius: 8px;
        display: flex;
       
        justify-content: center;
        cursor: pointer;
        
}@media only screen and (max-width: 991.98px) {
    .joinroom-text-div {
           display: none;
        }
                .joinroom-img {
                display: none;
                }
                                .nav-bar-img,.nav-bar-hello,.nav-bar-img-bold {
                                   
                                    font-size: 14px;
                                 
                
                                }
                               .nps-survey{
bottom: 10%;
width: 75%;
                                }
                                .header-text,.nps-survey-body-text,.survey-value,.survey-value-last,.survey-value-selected,.survey-value-last-selected,.survey-desc-text,.skip-for-now{
                                    font-size: 14px;
                                }
.survey-submit-button{
    font-size: 14px;
    width: 6rem;
}
.header-text{
    width: 50%;
}
                                
}