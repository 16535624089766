.joinInnerContainer {
    padding: 0;
    padding-top: 2rem;
    margin: 0;
    height: 76vh;
    display: flex;
    justify-content: center;

    width: 100%;
    background-image: url("../../../assets/wiv/theme.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

}
.config-table-header {
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    flex-direction: row;
    color: #000;

    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: auto;
}

.drop-down-btn,
.drop-down-btn:hover {
    margin-top: 20px;
    background-color: #e60000;
    padding: 2vh 2vw;
    flex-shrink: 0;
    color: #FFF;

    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border: none;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.ddl-btn,
.ddl-btn:hover {
    background-color: #e60000;
    width: 100%;
    height: 41px;
    flex-shrink: 0;
    color: #FFF;

    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border: none;
    border-radius: 8px;
}

.table-icons {
    padding: 1rem 0 0 1rem;
}

.label {
    width: 424px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.toggle {
    display: flex;

    flex-direction: column;
}

.subscriber-card {
    background-color: rgba(153, 140, 140, 0.201);
    border-left: #e60000 3px solid;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 5rem;
    flex-direction: column;

    margin-top: 0.5rem;

}

.subscriber-card-title {

    color: #000;

    text-align: center;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1rem;

}
.picker,
.react-datetime-picker__calendar-button {
    background-color: white !important;
    
}