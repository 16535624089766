.btn-back-envirtual-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
}

.ddl {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.asc3-cards-flip-container {
    min-height: 100vh;
    padding: 0;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    overflow-y: auto;

}

@media (min-width: 320px) and (max-width: 480px) {
    .asc3-cards-flip-container {
        height: 100vh;
        padding: 0;
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
        overflow: hidden;
    }
}

.header-text {
    font-family: 'Polaris' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;

    color: #FFFFFF;
}

.description-text {

    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}

.btn-styling {
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    background: #E60000;
    border-radius: 6px;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    padding: 0.75rem 1.25rem;
    color: #FCFCFC;
    border: none;
}

.btn-styling:hover {
    background: #C30000;

    text-shadow: none;
}

.back-btn-label {
    margin-top: 2.25rem;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}

.mentor-card-screen-background {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("/src/assets/octagonbg.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* object-fit: cover; */
    min-height: 100%;
    min-width: 100vw;
    margin: 0;
    /* Set up proportionate scaling */
    /* width: 100%;
      height: auto; */

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}

.main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 72vh;
    align-self: center;
    justify-self: center;
   
    background-image: url("/src/assets/gameIns.svg");
    
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
}
.main-div-leader-board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 72vh;
    align-self: center;
    justify-self: center;

    background-image: url("/src/assets/leaderBoard.svg");

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.title{
    font-family: 'Polaris' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        /* identical to box height */
    
    padding-right: 20rem;
        color: #FFFFFF;
}
.title-leader-board{
    font-family: 'Polaris' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        /* identical to box height */
    position: relative;
        top: 4rem;
        left: 7.5rem;
        color: #FFFFFF;
}
.title-leader-board-div{
    position: relative;
        top:4rem;
        right: 17rem;
        color: #E60000;
}
.title2-leader-board{
    font-family: 'Vodafone Rg';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        /* identical to box height */
    
    padding-left: 1.5rem;
        color: #E60000;
}
.instructions-div{
    width: 50%;
    height: 50%;
        justify-content: space-between;
        display: flex;
        align-items: flex-start;
        padding-left: 1rem;
    flex-direction: column;

}
.instructions-div-leaderBoard{
    width: 35%;
    height: 50%;
        justify-content: space-between;
        display: flex;
        align-items: flex-start;
        padding-left: 1rem;
    flex-direction: column;
}
.instruction-label{
    font-family: 'Vodafone RG';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
   text-align: start;
        color: #FFFFFF;
}
.btn-styling-div{
    display: flex;
    align-items: center;
    width: 100%;
        justify-content: center;
}
.btn-styling {
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    background: #E60000;
    border-radius: 6px;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    padding: 0.75rem 1.25rem;
    color: #FCFCFC;
    border: none;
}

.btn-styling:hover {
    background: #C30000;

    text-shadow: none;
}
.leader-main-item{
    display: flex;
    flex-direction: row;
    border-bottom: 1px #FFFFFF solid;
    align-items: center;
    width: 100%;
        justify-content: space-between;
}
.leader-item-number{
    background: #C30000;
    width: 2rem;
    height: 2rem;
    font-family: 'Vodafone Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */
    
    border-radius: 50%;
        color: #FCFCFC;
        display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
}
.leader-item-name{
font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */


    color: #FCFCFC;
}
.leader-name-div{
        display: flex;
            align-items: center;
}
.leader-qt{
    display: flex;
    align-items: center;
}
.leader-t{
    padding-right: 2.5rem;
}
.img-leader{
    width: 1.5rem;
    margin-right: 0.5rem;
}