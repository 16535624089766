.btn-back-envirtual-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
}

.ddl {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.asc3-cards-flip-container {
    min-height: 100vh;
    padding: 0;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    overflow-y: auto;

}

@media (min-width: 320px) and (max-width: 480px) {
    .asc3-cards-flip-container {
        height: 100vh;
        padding: 0;
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
        overflow: hidden;
    }
}

.header-text {
    font-family: 'Polaris' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;

    color: #FFFFFF;
}

.description-text {

    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}

.btn-styling {
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    background: #E60000;
    border-radius: 6px;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    padding: 0.75rem 3.25rem;
    color: #FCFCFC;
    border: none;
}

.btn-styling:hover {
    background: #C30000;

    text-shadow: none;
}

.back-btn-label {
    margin-top: 2.25rem;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}
.asc3-joinOuterContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("/src/assets/photoboothentrance2.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 132%;
    /* object-fit: cover; */
    min-height: 100%;
    min-width: 100vw;

    /* Set up proportionate scaling */
    /* width: 100%;
  height: auto; */

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}
.takePhoto{
    height: 15rem;
 cursor: pointer;
    margin-bottom: 5rem;
}
.mentor-card-screen-background {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("/src/assets/photoboothentrance2.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 120%;
    /* object-fit: cover; */
    min-height: 100%;
    min-width: 100vw;
    margin: 0;
    /* Set up proportionate scaling */
    /* width: 100%;
      height: auto; */

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}

.main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    align-self: flex-start;
    justify-self: center;
    padding-bottom: 7.5%;
}