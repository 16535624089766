.card-body {
    margin-top: 1rem;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 6px solid var(--unnamed-color-000000);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 6px solid #000000;
    border-radius: 6px;
    opacity: 1;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    margin: 2rem;
}

.button-style {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-rg);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 24px/32px Vodafone Rg;
    letter-spacing: 0.48px;
    color: #FFFFFF;
    opacity: 1;
    cursor: pointer;
    background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
    background: #E60000 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    border: none;
    padding: 0.5rem 1rem;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-4);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 24px/30px Vodafone Rg;
    letter-spacing: 2.4px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
}

.view-All {
    text-align: right;
    text-decoration: underline;
    font: normal normal normal 16px/18px Vodafone Lt;
    letter-spacing: 0px;
    color: #E60000;
    opacity: 1;
    cursor: pointer;
}

.page-content {

    height: 79vh;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
}

.page-title {
    text-align: left;
    font: normal normal normal 24px/28px Vodafone Rg;
    letter-spacing: 0.6px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
}

.info-bar {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    align-items: flex-end;
    width: 100%;
}

.upload-btn {
    width: 200px;
    height: 39px;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 16px/18px Vodafone Rg;
    letter-spacing: 0.16px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
    background: #E60000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    border: none;
    cursor: pointer;

}

.dropdown {
    border: #E60000 2px solid;
    margin: 0px 2rem 0px 0;
    width: 200px;
    height: 39px;
    text-align: left;
    font: normal normal normal 16px/18px Vodafone Rg;
    letter-spacing: 0.16px;
    color: #E60000;
    opacity: 1;
    border: 1px solid #E60001;
    border-radius: 4px;
    opacity: 1;
    background: transparent;
}

.search-box {
    width: 400px;
    height: 39px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    opacity: 1;
    align-self: flex-end;
}

.footer {
    display: flex;
    justify-content: center;
}

.delete {
    background: #4A4D4E 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: white;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: 0 0.25rem;
    font-size: smaller;
}

.name {
    text-align: left;
    font: normal normal normal 24px/28px Vodafone Rg;
    letter-spacing: 0.6px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
}

.email {

    text-align: left;
    font: normal normal normal 14px/16px Vodafone Lt;
    letter-spacing: 0.35px;
    color: #999999;
    opacity: 1;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
}

.modal-body {
    margin-top: 0.25rem;
    padding: 1rem;
    background-color: white;
    border-radius: 6px;
    z-index: 10000000000;
}

.cycle {
    margin-top: 0.5rem;
    text-align: left;
    font: normal normal normal 16px/18px Vodafone Rg;
    letter-spacing: 0.4px;
    color: #999999;
    opacity: 1;
    margin-bottom: 1rem;
}

.step {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.step-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.step-body {
    padding: 0.5rem 5%;
    width: 100%;
}

.step-title {
    text-align: left;
    font: normal normal normal 24px/28px Vodafone Rg;
    letter-spacing: 0.6px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
}

.step-value {
    text-align: right;
    font: normal normal normal 24px/28px Vodafone Rg;
    letter-spacing: 0.6px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
}

.line {
    border-bottom: #4A4D4E 1px solid;
}

.status-returned {
    color: #990000;
}

.status-approved {
    color: #009900;
}

.status-nothandled {
    color: #EB9700;
}

.footer {
    display: flex;
    justify-content: center;
}

.input {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    opacity: 1;
    width: 44px;
    height: 23px;
}

.Next {
    color: var(--unnamed-color-ffffff) !important;
    text-align: center !important;
    font: normal normal normal 16px/18px Vodafone Rg !important;
    letter-spacing: 0.16px !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    opacity: 1;
    padding: 0.5rem 1rem !important;
    background: #E60000 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    border: none;
}

.Saved {
    text-align: left;
    font: normal normal normal 16px/18px Vodafone Rg;
    letter-spacing: 0.16px;
    color: #E60000;
    opacity: 1;
    padding: 0.5rem 1rem;
    background-color: transparent !important;
    border: 1px solid #E60001;
    border-radius: 4px;
}

.chart {


    height: 380px !important;
    width: 100% !important;
}
.main-cards {
    display: flex;
    flex-direction: row;
    width: 97%;
}

.page-content {
    display: flex;
    flex-direction: row;
}