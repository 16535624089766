.mentor-card-screen-background {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("/src/assets/asc-3-bg.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 120%;
    /* object-fit: cover; */
    min-height: 100vh;
    min-width: 98vw;
    margin: 0;
    /* Set up proportionate scaling */
    /* width: 100%;
      height: auto; */

    /* Set up positioning */
    overflow-x: hidden;
}

.mask {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("/src/assets/octagons.svg");

    background-position: center;
    background-repeat: repeat;

    /* object-fit: cover; */
    min-height: 100%;
    min-width: 100vw;
    margin: 0;
    /* Set up proportionate scaling */
    /* width: 100%;
      height: auto; */

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}

.btn-back-envirtual-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;

}

.ddl {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.back-btn-label {
    margin-top: 2.25rem;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}
.title{
    font-family: 'Polaris' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 41px;
    
        color: #FFFFFF;
}
.sub-title {
    font-family: 'Vodafone Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
    
        color: #FCFCFC;
}
.btn-styling {
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    background: #E60000;
    border-radius: 6px;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    padding: 0.75rem 1.25rem;
    color: #FCFCFC;
    border: none;
}

.btn-styling:hover {
    background: #C30000;

    text-shadow: none;
}