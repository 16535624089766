.back-btn-label {
    margin-top: 2.25rem;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}

.btn-back-envirtual-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    z-index: 100;
}

.ddl {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.video-background {
cursor: pointer;
    width: 100%;
    height: 40vh;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    border: #fff 5px solid;
}