.joinInnerContainer {
    padding: 0;
    padding-top: 1rem;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url("../../assets/wiv/theme1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
height: 100%;
}
.container-fluid {
    max-width: 100% !important;

    height: 100vh;

}

.row {
    height: 90vh;
    padding: 0;
        margin: 0;
}
.img-photobooth{
    width: 80%;
        height: 80%;
    float: right;
}
.main-text{
    color: #000;
        text-align: center;
       
        font-size: 2vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        
}
.main-text-for-or{
    color: #000;
        text-align: center;
    
        font-size: 2vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-self: flex-start;
        align-self: flex-start;
        padding: 0 0.5vw 0 0.5vw;
}
.main-btns-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.5vw 0 1.5vw 0;
}
.btn-inner-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.btn-inner-div-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.btn{
    color: #FFF;
    width: 20vw;
        height: 10vh;
        text-align: center;
        font-size: 1.5vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.255px;
        border-radius: 6px;
            background: var(--Vodafone-red, #E60000);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
}
.btn-text{
    color: #000;
width: 20vw;
    height: 17vh;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 1vh;
}

.main-divs {
    display: flex;
    justify-content: center;
margin-top: 7.5vh;
  
}
.main-text-for-or-mobile-portrait{
    display: none;
}
.main-btns-div-mobile-portrait{
    display: none;
}
.back-div{
        position: absolute;
            margin-top: 11vh;
            margin-left: 1vw;
            z-index: 100;
            cursor: pointer;
            top: 2vh;
        left: 9vw;
            display: flex;
            justify-self: flex-start;
            width:3%
}
.first-div-row{
    align-items: center;
}
@media only screen and (orientation: portrait){
    .first-div-row {
            align-items: flex-start;
        }
    .back-div{
        position: absolute;
            margin-top: 11vh;
            margin-left: 1vw;
            z-index: 100;
            cursor: pointer;
            top: 2vh;
            left: 23.5vw;
            display: flex;
            justify-self: flex-start;
    }
    .main-text-for-or-mobile-portrait{
    display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 0 5.5vw !important;
        width: 100%;
        align-items: center;
        font-weight: bolder;
    }
        .main-btns-div-mobile-portrait{flex-direction: column;
            display: flex;
            align-items: flex-start;}
    .row{
    height: 90vh;
    }
    .joinInnerContainer{
            padding: 0 2.5vw 0 4rem !important;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
    }
    .img-photobooth{
            width: 41vw;
                float: left;
                height: 23vh;
    }
    .main-text-for-or,.btn-inner-div-2{
        display: none;
    }
    .main-divs{
        margin-top: 0;
        padding-left: 2rem;
        justify-content: flex-start;
    }
    .main-text{
        text-align: left;
      
            padding: 0;
            font-size: 4vw;
    }
    .btn-text{
    font-size: 3vw;
        width: 100%;
        text-align: left;
        height: auto;
            padding-bottom: 3vw;
    }
    .btn{
    width: 30vw;
        height: 3.5vh;
            text-align: center;
        font-size: 2.5vw;
    }
    .main-btns-div{
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
    .btn-inner-div {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;}
                 .btn-inner-div-2-mobile-portrait {
                    display: flex;
                     flex-direction: column-reverse;
                     align-items: flex-start;
                     justify-content: flex-start;
                     width: 100%;
                     
                 }
                                 hr.new4 {
                                     border: 0.0055vw solid black;
                                     padding: 0 2vh;
                                     width: inherit;
                                 }
}