

.img-photobooth {
    width: 398.546px;
    height: 335.354px;
    float: right;
}

.main-text {
    color: #000;
    text-align: center;

    font-size: 36px;
    font-style: normal;
    font-weight: 600;
 
    max-width: 587px;
}

.main-text-for-or {
    color: #000;
    text-align: center;

    font-size: 36px;
    font-style: normal;
    font-weight: 600;

    display: flex;
    justify-self: flex-start;
    align-self: flex-start;
    padding: 0 1rem 0 1rem;
}

.main-btns-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 2rem 0;
}

.btn-inner-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btn {
    color: #FFF;
    width: 253px;
    height: 60px;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;

    letter-spacing: -0.255px;
    border-radius: 6px;
    background: var(--Vodafone-red, #E60000);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.btn-text {
    color: #000;
    width: 259px;
    height: 53px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;

    margin-top: 1vh;
}

.main-divs {
    display: flex;
    justify-content: center;
    margin-top: 7.5rem;

}
.img-header-cont{
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-side{
    display: flex;
    flex-direction: column;
    justify-content: center;


}
.left-side-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.brought-div{
    display: flex;
        flex-direction: column;
        justify-content: center;
  width: inherit;
}
.brought-to-you-by{
    color: var(--Vodafone-red, #E60000);
        font-size: 1.75vw;
        font-style: normal;
        font-weight: 700;
        
        letter-spacing: -0.36px;
}
.department-title{
color: #000;
    font-size: 1.75vw;
    font-style: normal;
    font-weight: 500;

    /* 133.333% */
    letter-spacing: -0.36px;
    width: inherit;
}
.names-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
    margin-left: -1rem;

}
.name-record{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1.5vh;
}
.first-div-row{
    width: 100%;
        padding: 0 0 0 10vw !important;
}
.wiva-header{
    display:flex;
    justify-content: center;
    align-items: center;

}
.name{
    color: #000;
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 700;
  
        letter-spacing: -0.255px;
        padding-right: 2rem;
      

}
.title{
    color: #000;
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 500;
 
        letter-spacing: -0.255px;
       
}
.main-header-img{
    height: 10rem;
        margin: -5rem !important;
}
.it-logo{
        margin-right: -4vw !important;
        margin-left: -7vw !important;
            margin-bottom: -2vw !important;
            width:20vw;
}
.main-header-img{
    width:12vw;
    height: 19vh;
}
.connect-logo{
    width: 12vw;
}

.names2-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 11.5vh;
width: 75%;

}
.right-side{

            align-items: center;
            display: flex;
            flex-direction: column;
}
.brought2-div{
    display: flex;
        flex-direction: column;
        width: inherit;
        width: 75%;
}
.joinInnerContainer {
padding-top: 5rem;
    margin: 0;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    background-image: url("../../assets/wiv/theme1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
}

.container-fluid {
    max-width: 100% !important;

    height: 100vh;

}

.row {
    height: 90vh;
    padding: 0;
    margin: 0;
}
.back-div {
    position: absolute;
    margin-top: 11vh;
    margin-left: 1vw;
    z-index: 100;
    cursor: pointer;
    top: 2vh;
    left: 9vw;
    display: flex;
    justify-self: flex-start;
    width: 3%;
}
@media only screen and (orientation: portrait) {
    .joinInnerContainer{
        padding-top: 5rem;
    }
    .back-div {
            position: absolute;
            margin-top: 11vh;
            margin-left: 1vw;
            z-index: 100;
            cursor: pointer;
            top: 2vh;
            left: 23.5vw;
            display: flex;
            justify-self: flex-start;
            width: 3%;
        }
    .names-div{
padding: 0 0 0 4vh;}
.name,.title{
    font-size: 2vw;
}
.main-header-img{
    width: 27vw;
        height: 11vh;
}.first-div-row{
    padding: 0 0 0 16vw !important;
}.department-title,.brought-to-you-by{font-size: 4vw;}.it-logo{width: 39vw;}
.connect-logo{
    width: 25vw;
}
.names2-div,.brought2-div{
    width: 100%;
    padding-top: 4vh;
    padding: 0 0 0 2vh;
}
.left-side-header{
    padding-bottom: 4vh;
}
}