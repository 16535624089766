#videopoc-video > video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.mentor-card-screen-background{
  background-image: url("../../../assets/background.png");
  background-repeat: no-repeat;
background-size: 100% 100%;
}
.logoContainer{
  position: absolute;
  right: 2%;
  bottom: 2%;
  height: 5rem;
}