
.joinInnerContainer {
    padding: 0;
    padding-top: 1rem;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url("../../assets/wiv/theme1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
}

.container-fluid {
    max-width: 100% !important;

    height: 100vh;

}

.row {
    height: 90vh;
    padding: 0;
    margin: 0;
}
.back-div {
    position: absolute;
    margin-top: 11vh;
    margin-left: 1vw;
    z-index: 100;
    cursor: pointer;
    top: 2vh;
    left: 9vw;
    display: flex;
    justify-self: flex-start;
    width: 3%;
}

.tip-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
    align-items: center;
    padding: 1vw 1vw;
    width: 100%;
}
.tip-number {
    color: #FFF;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 2vw;
    height: 2vw;
    border-radius: 6px;
    background-color: #e60000;
    margin-right: 0.5vw;


}

.tip-text {
    color: #000;

    text-align: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.section-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 9vw;
    justify-content: space-between;
}
.section-div-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 2vw;
}
.video-cnvas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
      
}
.photo-video-frame{
        width: 66% !important;
   
}
.btn {
    color: #FFF;
        width: 20vw;
        height: 10vh;
        text-align: center;
        font-size: 1.5vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.255px;
        border-radius: 6px;
        background: var(--Vodafone-red, #E60000);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 2vh;
        border: none;
}
.btn-2,.btn-2:hover {
    color: var(--Vodafone-red, #E60000);
    border: var(--Vodafone-red, #E60000) 1px solid;
    width: 20vw;
    height: 10vh;
        font-size: 1.5vw;
    text-align: center;

    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border-radius: 6px;
    background:transparent ;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 2vh;
    text-decoration: none;
}
.border-right{
    border-right:#000 1px solid;
}
.border-bottom {
    border-bottom: none;
   
    
}
.visitor-bg {
    
width: 21vw;
    height: 50vh;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    border: 1vw #BCBEC0 solid;
    flex-direction: column;
    border-radius: 6px;

}
.img{
    width: 80%;
}
.welcome-msg{
    text-transform: uppercase;
        color: #000;
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
}
.first-div-row {
    padding-top: 4vh;
    width: 100%;
}
@media only screen and (orientation: portrait) {
    .tip-text {font-size: 5vw;margin-left: 2vw;}
        .tip-number {font-size: 5vw; width: 6vw; height: 3vh;}
    .border-right{
        border: none;
    }
    .border-bottom{
        border-bottom: #000 1px solid;
    }
    .section-div {padding-left: 23.5vw;
    }
.back-div {
        position: absolute;
        margin-top: 11vh;
        margin-left: 1vw;
        z-index: 100;
        cursor: pointer;
        top: 2vh;
        left: 23.5vw;
        display: flex;
        justify-self: flex-start;
        width: 3%;
    }
       
         .row {
             height: 90vh;
         }
    
         .joinInnerContainer {
          
             display: flex;
             flex-direction: column;
             justify-content: space-evenly;
         }
                  .first-div-row {
                    padding-top: 4vh;
                        width: inherit;
                  }
 .btn {
     width: 30vw;
     height: 3.5vh;
     text-align: center;
     font-size: 2.5vw;
     margin-bottom: 1vh;
 }
 .btn-2,
 .btn-2:hover {width: 30vw;
    height: 3.5vh;
    text-align: center;
    font-size: 2.5vw;}
.visitor-bg {
width: 45vw;
    height: 36vh;
            padding: 2vh 0;
}}