/* photobooth section */

.photobooth-container {
  overflow-x: hidden;
}

#photos {
  display: flex;
  flex-direction: row;
}

#photos .frame-Container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

#photos img {
  background-repeat: no-repeat;
  /* margin-left: 4%; */
}

#canvas {
  display: none;
}

.video-top-container {
  /* 500 px */
  /* width: 31rem; */
  margin: 3rem auto;
}

.video-bottom-container {
  max-width: 98%;
  margin: auto;
  padding: 1rem;
}

.photobooth-img {
  object-fit: cover;
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 1024px) {

  /* Specific to this particular image */
  .photobooth-img {
    left: 50%;
    margin-left: -512px;
    /* 50% */
  }
}

@media screen and (min-height: 768px) {

  /* Specific to this particular image */
  .photobooth-img {
    height: 150vh;
  }
}

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.Demo__some-network__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width: 32px;
  height: 32px;
}