.form {
    display: flex;
 justify-content: space-between;
 align-items: center;

}

.input {
    border: none;
    border-radius: 0;
 
    width: 94.5%;
    font-size: 1.2em;
border-radius: 8px;
    padding: 0.5rem;
    fill: #BABABA;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFF;
            border-radius: 8px;
            /* border-radius: 4px 4px 0 0; */
            min-height: 40px;
            width: 100%;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.send-btn,.send-btn:hover {

    text-transform: uppercase;
    text-decoration: none;
    border: none;
    width: 47px;
        height: 47px;
        background-color: #e60000;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
 
}
.colorDark {
    color: var(--Vodafone-Black, #25282B);
}
.messageTextleft {
    text-align: left;
    width: 100%;
    letter-spacing: 0;
    float: left;
    font-size: 1.2vw;
    word-wrap: break-word;
    margin: 0;
}
.messageBox {
    background: #F3F3F3;
    border-radius: 8px;
    padding: 0.5rem;
    color: white;
    display: inline-block;
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.backgroundLight {
    background-color: #F7F7F7;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: #FFF;

    text-align: center;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.255px;
       
   
}
.colorDark-name {
    color: var(--Vodafone-Black, #25282B);
    font-weight: 600;
}
.reply-span{
background-color: #ffc5c554;
    width: 95%;
    border-left: 5px solid #25282B;
    padding: 1vw;
    height: 100%;
    border-radius: 6px;
    color: grey !important;
  
}
.removereply{
    width: 1rem;
        height: 1rem;
        cursor: pointer;
}
.wivasuggestion-item{
    padding: 1rem;
}
.wivaautocomplete-list{
    background-color: white;
}
.emojis{
    position: absolute;
        /* margin-bottom: 5rem; */
        background-color: transparent;
        border: none;
        right: 9%;
}
.emoji-img{
    width: 1.5rem;
    height: 1.5rem;
}
@media only screen and (orientation: portrait) {
    .emojis{
        right: 20%;
    }
}