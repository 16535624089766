.joinInnerContainer {
    padding: 0;
    padding-top: 1rem;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url("../../assets/wiv/theme1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
}

.container-fluid {
    max-width: 100% !important;

    height: 100vh;

}

.row {
    height: 90vh;
    padding: 0;
    margin: 0;
}

.back-div {
    position: absolute;
    margin-top: 11vh;
    margin-left: 1vw;
    z-index: 100;
    cursor: pointer;
    top: 2vh;
    left: 9vw;
    display: flex;
    justify-self: flex-start;
    width: 3%;
}

.tip-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
    padding: 1vw 1vw;
    width: 100%;
}

.tip-number {
    color: #FFF;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 2vw;
    height: 2vw;
    border-radius: 6px;
    background-color: #e60000;
    margin-right: 0.5vw;


}

.tip-text {
    color: #000;

    text-align: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.section-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 9vw;
    justify-content: space-between;
}

.wiv-frame-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.summit-mainframe{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem ;
    height: 50vh;
    width: 50vw;
        background-repeat: no-repeat;
        background-size: 100% 100%;
}
.summit-frame-selected {
    border: #e60000 0.5vw solid;
    width: 100%;
}
.summit-video{
            height: 34vh;
                width: 18vw;
                object-fit: cover;
              
}

.summit-video3and4 {
width: 20vw;
    height: 45vh;
    z-index: 100000;
    left: 1.75vw;
    position: relative;
}
.summit-frame{
    position: absolute;
    width: 25vw;
    height: 50vh;
}
.summit-frame-container{
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}



.finalize-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-styling:disabled {
margin-top: 2vh;
    color: #FFF;
    width: 20vw;
    height: 10vh;
    font-size: 1.5vw;
    text-align: center;

    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border-radius: 6px;
    background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
    background: #CCCCCC;
    border-radius: 8px;
    opacity: 1;
    border: none;
}
.btn-styling2,
.btn-styling2:hover {
    color: var(--Vodafone-red, #E60000);
    border: var(--Vodafone-red, #E60000) 1px solid;
    width: 20vw;
    height: 10vh;
    font-size: 1.5vw;
    text-align: center;

    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border-radius: 6px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 2vh;
    text-decoration: none;
    margin-left: 1vw;
}
.btn-styling,.btn-styling:hover {
margin-top: 2vh;
    color: #FFF;
    width: 20vw;
    height: 10vh;
    font-size: 1.5vw;
    text-align: center;

    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border-radius: 6px;
    background: var(--Vodafone-red, #E60000);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;

}

.btn-styling:hover:enabled {
    background-color: #565555;
    text-shadow: none;
    color: white !important;
    text-decoration: none !important;
}

@media only screen and (orientation: portrait) {
    .tip-text {
            font-size: 5vw;
            margin-left: 2vw;
        }
    
        .tip-number {
            font-size: 5vw;
            width: 6vw;
            height: 3vh;
        }
    .summit-mainframe {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            height: 60vh;
            width: 80vw;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
.summit-video {
    height: 23vh;
        width: 56vw;
        object-fit: cover;
        margin-bottom: 2vh;
    }
    .btn-styling,
    .btn-styling:disabled,.btn-styling2,.btn-styling2:hover {
        width: 30vw;
        height: 3.5vh;
        text-align: center;
        font-size: 2.5vw;
    }

    .first-div-row {
        padding-left: 17.5vw;
    }

    .back-div {
        position: absolute;
        margin-top: 11vh;
        margin-left: 1vw;
        z-index: 100;
        cursor: pointer;
        top: 2vh;
        left: 23.5vw;
        display: flex;
        justify-self: flex-start;
        width: 3%;
    }

    .row {
        height: 90vh;
    }

    .joinInnerContainer {

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .first-div-row {
        padding-top: 4vh;
        width: inherit;
    }
}