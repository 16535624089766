

.wiva-replycolorDark-name {
    color: var(--Vodafone-Black, #25282B);
    font-weight: 600;
}

.wiva-reply-span {
    background-color: #ffc5c554;
    width: 100%;
    border-left: 5px solid #e60000;
    padding: 0.5rem;
    height: 100%;
    border-radius: 6px;
    color: grey !important;
    max-height: 10rem;
    overflow-y: auto;
    padding: 0.75rem 0;
    margin: 0.5rem 0rem;
    float: right;

}
.wiva-replymessageTextleft {
    text-align: left;
    width: 100%;
    letter-spacing: 0;
    float: left;
font-size: 1.25vw;
    word-wrap: break-word;
    margin: 0;
      
}
.wiva-replycolorDark {
    color: var(--Vodafone-Black, #25282B);
       
}
@media only screen and (orientation: portrait) {
    .wiva-replymessageTextleft{
        font-size: 4vw;
    }
}