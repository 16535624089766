.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    border-radius: 8px;
    /* border-radius: 4px 4px 0 0; */
    
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.leftInnerContainer {
 display: flex;
        align-items: flex-start;
        margin-left: 5%;
        margin: 1% 5%;
        color: #fff;
     flex-direction: column;
}

.rightInnerContainer {
    display: flex;
    flex: 0.5;
    justify-content: flex-end;
    margin-right: 5%;
}

.onlineIcon {
    margin-right: 5%;
}
.header{
    color: #000;
    
        font-size: 2vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
}
.sub-header{
color: #000;

    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-height: 5vh;
        overflow-y: auto;
}
@media only screen and (orientation: portrait) {
.header{
        font-size: 6vw;
}.sub-header{
 font-size: 4vw;
}}