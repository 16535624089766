@font-face {
  font-family: Vodafone RG;
  src: url("../src/assets/fonts/VodafoneRg.ttf");
}

@font-face {
  font-family: Vodafone RG;
  src: url("./assets/fonts/VodafoneRgBd.ttf");

  font-weight: 700;
}

@font-face {
  font-family: Vodafone Lt;
  src: url("../src/assets/fonts/VodafoneLt.ttf");
}

@font-face {
  font-family: Vodafone Lt;
  src: url("./assets/fonts/VodafoneLtBd.ttf");

  font-weight: 700;
}

@font-face {
  font-family: "Polaris";
  src: url("../src/assets/fonts/Polaris.otf");
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
span,
button {
  font-family: "Vodafone RG" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mentor-card-screen-background {
  object-fit: cover;
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {

  /* Specific to this particular image */
  .mentor-card-screen-background {
    left: 50%;
    margin-left: -512px;
    /* 50% */
  }
}

@media screen and (min-height: 768px) {

  /* Specific to this particular image */
  .mentor-card-screen-background {
    height: 150vh;
  }
}

.emm-cinem-envirtual-container {
  background-image: url("../src/assets/WebBG.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.cinema-envirtual-container {
  background-image: url("../src/assets/cinema.PNG");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.cinema-asc-container {
  background-image: url("../src/assets/asc-cinema.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 111% 153%;
  overflow: hidden;
}

.cinema-finops-container {
  background-image: url("../src/assets/finops-cinema.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 120%;
  overflow: hidden;
}

.logo-rightContainer {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 1rem;
}

.row-envirtual-container {
  height: 100%;

  padding-top: 1%;
}

.QA-envirtual-container {
  background-color: transparent;
  border: none;
}

.video-envirtual-container {
  height: 100vh;
  width: 30%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.slido {
  z-index: 10;
}

.slido-envirtual {
  z-index: 10;
  width: 80%;
  min-height: 90vh;
  z-index: 1;
  border-radius: 5px;
  border: none;
}

.slido-envirtual-container {
  margin: -47% 1.8%;
  display: flex;
  height: 80vh;
  width: 24%;
  z-index: 100;
}

.QA-envirtual-button {
  margin: 6% -11%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.btn-back-envirtual-container {
  width: 100%;
}

.mentors-header-page-title {
  color: white;
}

.recaptcha-container {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 2rem;
}

/* backdrop */
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #222222;
  opacity: 0.9;
  z-index: 1000;
}

.backdrop {
  width: 94%;
  height: 100%;
  background: #222222;
  opacity: 0.9;
  z-index: 1000;
  border-radius: 50px;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

#loader-wrapper .loader-section .section-text {
  color: white;
  opacity: 1;
}

div.clickEffect {
  position: fixed;
  box-sizing: border-box;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 50%;
  animation: clickEffect 0.4s ease-out;
  z-index: 99999;
}

@keyframes clickEffect {
  0% {
    opacity: 1;
    width: 0.5em;
    height: 0.5em;
    margin: -0.25em;
    border-width: 0.5em;
  }

  100% {
    opacity: 0.2;
    width: 15em;
    height: 15em;
    margin: -7.5em;
    border-width: 0.03em;
  }
}

.frontImage {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  border-radius: 10px;
}

/* about */
.video-link-container {
  position: relative;
  top: 0;
  right: 0;
  height: 100vh;
}

.virtual-img-right {
  margin-left: 20%;
  width: 80%;
  height: 80%;
}

.virtual-img-left {
  width: 80%;
  height: 80%;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-top: 20px;
  padding: 0;
}

.pagination a {
  cursor: default;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e60000;
  color: #e60000;
  margin-left: 10px;
}

.pagination li:not(.disabled) a:hover {
  background-color: bisque;
  cursor: pointer;
}

.pagination li a {
  font-weight: bold;
}

.pagination li.active a {
  color: #fff;
  background: #e60000;
}

.pagination li.disabled a {
  pointer-events: none;
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.asc-cards-flip-container {
  min-height: 100vh;
  padding: 0;
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  overflow-y: auto;
}

@media (min-width: 320px) and (max-width: 480px) {
  .asc-cards-flip-container {
    height: 200vh;
    padding: 0;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    overflow: hidden;
  }
}

.transition-asc-img {
  transition: all 0.2s ease-in-out;
}

.transition-asc-img:hover {
  transform: scale(1.05);
}

.asc-demo-image {
  width: 92vw;
  height: 80vh;
}

#env-options-wrapper {
  position: fixed;
  bottom: 0;
  width: 50%;
  height: 5%;
  background: #222222;
  opacity: 0.9;
  z-index: 999;
  display: flex;
  flex-direction: row;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
  height: 52px;
  margin-bottom: 2rem;
  text-align: center;
  font: normal normal normal 18px/21px Vodafone Rg;
  letter-spacing: 0.09px;
  color: #808080;
  opacity: 1;
}

.jigsaw-puzzle {
  width: 38%;
  height: 38%;
  border: 2px solid black;

  margin: auto;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.25);
}

.demoframe {
  height: 100vh;
  overflow: hidden;
}

.magazine {
  display: flex;
  margin-top: 2.5rem;
  padding-top: 0;
  justify-content: center;
  align-items: flex-start;
  height: 80vh;
}

.magazine canvas {
  height: 95% !important;

  /* width: 90%!important; */
}

@media only screen and (min-width: 1366px) {
  .magazine canvas {
    height: 95% !important;
    max-width: 800px !important;
    /* width: 90%!important; */
  }
}

.magazine .page img {
  max-width: 100%;
  height: 100%;
}

.pager {
  display: flex;
  list-style: none;
}

.nac-pager {
  position: absolute;

  bottom: 0px;
  margin-bottom: 5rem;
}

.viewBtn {
  position: absolute;
  z-index: 10000000000000000;
  bottom: 5rem;
  width: 75%;
  background-color: black !important;
  border-radius: 10px !important;
  background: #000;
  padding: 1rem 0 !important;
  justify-content: center;
  display: flex;
  align-items: center;
}

.disapearBtn {
  display: none !important;
}

.button {
  background-color: transparent;
  color: white;
  border: none;
  display: inline !important;
  width: 7.5rem;
}

@keyframes changeBG {
  0% {
    width: 30%;
    height: 1rem;
  }

  10% {
    width: 40%;
    height: 2rem;
  }

  20% {
    width: 50%;
    height: 3rem;
  }

  30% {
    width: 60%;
    height: 3.5rem;
  }



  50% {
    width: 70%;
    height: 4rem;
  }

  60% {
    width: 60%;
    height: 4rem;
    position: absolute;
    top: 45%
  }

  70% {
    width: 50%;
    height: 3rem;
    position: absolute;
    top: 35%
  }

  80% {
    width: 40%;
    height: 2.8rem;
    position: absolute;
    top: 25%
  }

  90% {
    width: 25%;
    height: 2rem;
    position: absolute;
    top: 15%
  }

  90% {

    position: absolute;
    top: 10%
  }

  100% {
    width: 25%;
    height: 2rem;
    position: absolute;
    top: 5%
  }

}


@keyframes navigator {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.6;
  }

  75% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }



}

.home_logo_size {

  height: auto;
  opacity: 1;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  background: url('../src/assets/poweringLogo.png');
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  background-position: center;
}

.rec-slider-container {
  height: 85vh !important;
}

.rec-carousel {

  flex-direction: column !important;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 70vh !important;
}

.rec-item-wrapper{
  height: 7.5vw;
  width: 10vw !important;
  border: #F3F3F3 1px solid;
}
.rec-carousel-item{
  margin-bottom: 2vh;
  margin-top: 1vh;
}
.rec-arrow {
  margin: 2rem 0;
}

.rec-pagination {
  display: none !important;
}
@media only screen and (orientation: portrait) {.rec-slider-container {
        height: 9vh !important;
          width: 100vw !important;
  }

  .rec-carousel {

    flex-direction: row !important;
    display: flex;
    align-items: center;
    justify-content: center;


  }

  .rec-item-wrapper {
  height: 9vh;
    width: 29vw !important;
    border: #F3F3F3 1px solid;
            margin-left: 1vw;
  }
.rec-carousel-wrapper{
      height: 10vh;
}
  .rec-carousel-item {
    margin-bottom: 2vh;
    margin-top: 1vh;
  }

  .rec-arrow {
    margin: 2rem 0;
  }

  .rec-pagination {
    display: none !important;
  }}
.wiva-tag {
  color: #e60000;
}

.wivasuggestion-item {
  padding: 0.5rem;
  /* max-width: 10rem;
  min-width: 10rem; */
  cursor: pointer;
}

.wivaautocomplete-list {
  visibility: hidden;
  background: #F3F3F3;
  font-size: 1.2em;
  border-radius: 8px;
  padding: 0.5rem;
  fill: #BABABA;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  padding: 0.5rem;
  color: black;
  display: inline-block;
  max-height: 57vh;
  overflow-y: auto;

  width: 53%;
  position: fixed;

  bottom: 10%;
  z-index: 10000;
}
.wivaemoji-img {
  width: 1.5rem;
  height: 1.5rem;
}