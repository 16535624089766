.main-cards {
    display: flex;
    flex-direction: row;
    width: 97%;
}

.page-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 47%;
    z-index: 10000;
}

.viewers {
    display: flex;
    flex-direction: row;
    background-color: black;
    color: white;
    width: 5rem;
    justify-content: space-around;
    padding: 1rem;
    border-radius: 16px;
    align-items: center;
}

.emojis {
    display: flex;
    flex-direction: row;
    background-color: black;
    color: white;
    width: 25rem;
    justify-content: space-around;
    padding: 1rem;
    border-radius: 16px;
    align-items: center;
}

.emojis-conent {
    display: flex;
    flex-direction: row;

    align-items: center;
    position: absolute;
    bottom: 0;
    left: 37%;
    z-index: 10000;
}

.modal {
    position: absolute;
    top: 1%;
    left: 1%;
    z-index: 100001;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.survey-body {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
}

.emoji {
    display: flex;
    flex-direction: row;
    border-right: 1px grey solid;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.emoji-last {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.count {
    margin: 0 0.5rem;
}

@media only screen and (max-width: 991.98px) {
    .emojis-conent {

        left: 0;
    }
}

.modal {
    position: absolute;
    top: 0;
    left:0;
    z-index: 100005;
    width: 100%;
}
.header-container {
    border-radius: 0px 0px 8px 8px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(0.5px);
    width: 100%;
        height: 7rem ;
        flex-shrink: 0;
            padding: 0 1rem;
        margin: 0;
        display: flex;
            justify-content: space-between;
            align-items: center;
}
.logo{
    width: 104px;
        height: 38px;
}
.searchDiv{
    float: right;
    width: 65%;
}
.header{
    width: 100%;
}
.footer-container {
border-radius: 0px 0px 8px 8px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(0.5px);
    width: 100%;
    height: 10rem;
    flex-shrink: 0;
    padding: 0 1rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reset-img{
    margin: 1rem 1rem;
}