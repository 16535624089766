.joinOuterContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-image: url("../../../../assets/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* object-fit: cover; */
  min-height: 100%;
  min-width: 100vw;

  /* Set up proportionate scaling */
  /* width: 100%;
  height: auto; */

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

.joinInnerContainer {
  width: 20%;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.button {
  width: 100%;
  border-radius: 2%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 50%;
  }
  .heading {
    /* width: 23%; */
  }
  .btn-width {
    /* width: 23%; */
  }
  .join-btns-container {
    /* width: 23%; */
  }
  .back-btns-container {
    /* width: 23%; */
    display: flex;
    flex-direction: column;
  }
  .back-btns-container > a {
    margin-bottom: 1rem;
  }
}

button:focus {
  outline: 0;
}

@media (min-width: 764px) and (max-width: 770px) {
  .joinOuterContainer {
    /* display: flex; */
  }
  .joinInnerContainer {
    width: 37%;
    display: flex;
    flex-direction: column;
    /* margin-right: 23%; */
  }
}
@media (min-width: 538px) and (max-width: 542px) {
  .joinInnerContainer {
    width: 50%;
  }
}

@media (min-width: 278px) and (max-width: 282px) {
  .joinInnerContainer {
    width: 75%;
  }
  .back-btns-container > a {
    margin-right: 0.5rem;
  }
}
/* @media (min-width: 898px) and (max-width: 282px) {
  .joinInnerContainer {
    width: 50%;
  }
} */
