.btn-back-envirtual-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
}

.ddl {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.asc3-cards-flip-container {
    min-height: 100vh;
    padding: 0;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    overflow-y: auto;

}

@media (min-width: 320px) and (max-width: 480px) {
    .asc3-cards-flip-container {
        height: 100vh;
        padding: 0;
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
        overflow: hidden;
    }
}

.header-text {
font-family: 'Polaris' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    /* identical to box height */


    color: #FFFFFF;
}

.description-text {

    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}

.btn-styling {
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    background: #E60000;
    border-radius: 6px;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    padding: 0.75rem 1.25rem;
    color: #FCFCFC;
    border: none;
}

.btn-styling:hover {
    background: #C30000;

    text-shadow: none;
}

.back-btn-label {
    margin-top: 2.25rem;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}

.mentor-card-screen-background {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("/src/assets/forkbg.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* object-fit: cover; */
    min-height: 100vh;
    min-width: 100vw;
    margin: 0;
    /* Set up proportionate scaling */
    /* width: 100%;
      height: auto; */

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}

.main-div {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    align-self: center;
    justify-self: center;
  padding: 2rem 4rem 6rem 4rem;
  min-width: 25rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.46) -202.19%, rgba(255, 255, 255, 0.15) 100%);
        box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2.5px);
        /* Note: backdrop-filter has minimal browser support */
    
        border-radius: 8px;
        margin-right: 2rem;
}
.main{
    justify-content: center;
    align-items: center;
height: 75vh;
}