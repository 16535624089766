.asc-mosaic-wall-images {
    width: 17rem;
    height: 15rem;
    margin: 0.25rem 1.25rem;
    border-radius: 16px;
    opacity: 1;
}

.asc-mosaic-background {
    margin: 2% 0.75%;

    height: fit-content;
   
}
.back-btn-label {
    margin-top: 2.25rem;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}
.btn-back-envirtual-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;

}
.ddl {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}