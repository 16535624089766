.img {
    z-index: 9999;
    width: 15rem;

}
.instruction{
    font-family: 'Vodafone RG';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.75px;
        color: #FCFCFC;
       
}
.coming{
    font-family: 'Polaris' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 39px;
        /* identical to box height */
    
    
        color: #FFFFFF;
}
.asc3-timer{
    width: 142px;
    height: 142px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.46) -202.19%, rgba(255, 255, 255, 0.15) 100%);
        box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2.5px);
        /* Note: backdrop-filter has minimal browser support */
    
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 1rem;
}
.remaining{
    font-family: 'Vodafone RG';
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 73px;
    
        color: #FFFFFF;
}
.remaining2{
    font-family: 'Vodafone RG';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */
    
    
        color: #FFFFFF;
}