.textContainer {
display: flex;
    flex-direction: column;

    background-color: #F7F7F7;
    height: 80vh;
    justify-content: space-between;
    border-radius: 8px;
    padding: 1rem;
    overflow-y: auto;
    width: inherit;
}
.header{
color: #000;

    font-size: 2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.sub-header{
  color: #E60000;
  
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.circle{
    background-color: #E6E6E6;
    width: 2.5vw;
        height: 2.5vw;
        border-radius: 50%;
        text-transform: uppercase;
        padding: 0.5rem;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #000;
        text-align: center;
            font-size: 2vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
}
.online {
  width: 1.25vw;
  height: .5vw;
  position: absolute;
  margin-bottom: 5%;
  margin-left: 7.5%;
}
.activeContainer {
  display: flex;
  align-items: center;
  margin-top: 2vh;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
}

.activeItem {
padding: 2vh;
margin-bottom: 1vh;
    background-color: white;
    display: flex;
    justify-content: flex-start;

border-radius: 8px;
align-items: center;

color: #000;
width: 100%;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: initial;
}

.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}
.name{
  padding-left: 1vw;
}
@media (min-width: 320px) and (max-width: 1200px) {
  .textContainer {
 margin: 0;
  }
    .online {
      width: 2vw;
      height: .75vw;
      position: absolute;
      margin-bottom: 5%;
      margin-left: 2.5%;
    }
}
@media screen and (max-width: 579px) {
  .textContainer {
    text-align: center;
  }
}
@media screen and (max-width: 579px) {
 
}
@media only screen and (orientation: portrait) {.header{
  display: none;
}
.sub-header{display: none;}
 .activeContainer {
   justify-content: flex-start;
   flex-direction: row;
  
 }
 .textContainer{
  height: 100%;
  padding: 0;
        background-color: transparent;
          overflow-x: auto;
          overflow-y: hidden;
 }
 .activeItem {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 15vw;

  
 }
 .circle{
  background-color: #E6E6E6;
    width: 4.5vw;
    height: 4.5vw;
    border-radius: 50%;
    text-transform: uppercase;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #000;
    text-align: center;
    font-size: 3vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
 }
 .name{
  
  font-size: 3vw;
 }
 .online-container{
  margin-bottom: 0.5vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .online {
   width: 4.9px;
   height: 5.1px;}
}
