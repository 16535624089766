.main-cards {
    display: flex;
    flex-direction: row;
    width: 97%;
}

.page-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 47%;
    z-index: 10000;
}

.viewers {
    display: flex;
    flex-direction: row;
    background-color: black;
    color: white;
    width: 5rem;
    justify-content: space-around;
    padding: 1rem;
    border-radius: 16px;
    align-items: center;
}

.emojis {
    display: flex;
    flex-direction: row;
    background-color: black;
    color: white;
    width: 25rem;
    justify-content: space-around;
    padding: 1rem;
    border-radius: 16px;
    align-items: center;
}

.emojis-conent {
    display: flex;
    flex-direction: row;

    align-items: center;
    position: absolute;
    bottom: 0;
    left: 37%;
    z-index: 10000;
}

.modal {
    position: absolute;
    top: 1%;
    left: 1%;
    z-index: 100001;
    width: 100%;
}

.survey-body {
    display: flex;
    flex-direction: column;
}

.emoji {
    display: flex;
    flex-direction: row;
    border-right: 1px grey solid;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.emoji-last {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.count {
    margin: 0 0.5rem;
}
.radio{
    color: black;
}

@media only screen and (max-width: 991.98px) {
    .emojis-conent {

        left: 0;
        z-index: 200000;
    }
}