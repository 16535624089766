.main-container{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.img-size{
    width: 75%;
}
.screen-container{
    opacity: 0;
position: absolute;
    top: 0;
        width: 100vw;
        padding: 2rem 0.5rem;
        min-height: 100%;
}
.header-div{
    display: flex;
        flex-direction: row;
        height: 1rem;
}
.backDiv{
    display:  flex;
    flex-direction: row;
        cursor: pointer;
}
.back-text{
    color: #000;
        text-align: center;
        font-family: Vodafone;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.42px;
        text-transform: uppercase;
        margin-left: 0.25rem;
}
.skipDiv {
    position: absolute;
        display: flex;
        flex-direction: row;
        /* float: right; */
        right: 0.5rem;
            cursor: pointer;
}
.skip-text{
    color: #000;
        text-align: center;
        font-family: Vodafone;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.42px;
        text-transform: uppercase;
        margin-right: 0.25rem;
}
.main-page-container{
    display: flex;
    flex-direction: column;
    height: 60vh;
margin: 34% 0 0 0;
    align-items: center;
}
.step-img{
width: 211.204px;
    height: 264.409px;
}
.step-title{
    color: #000;
        text-align: center;
        font-family: Vodafone;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.6px;
        text-transform: uppercase;
            margin: 4rem 0 2rem 0;
}
.step-description{
    color: #000;
        text-align: center;
        font-family: Vodafone;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.54px;
}
.step-footer{
    display:  flex;
    justify-content: space-between;
    flex-direction: row;
    position: absolute;
    bottom: 2rem;
    width: 100%;
        padding: 0px px 1rem;
            align-items: flex-end;
}
.stepper-container{
    display: flex;
    flex-direction: row;
    float: left;
}
.active-step{
    width: 32px;
        height: 11px;
        flex-shrink: 0;
        border-radius: 13px;
            background: #000;
            margin: 0 0.5rem;
        background-size: contain;
        background-repeat: no-repeat;
        justify-content: center;
}
.inActive-step{
    width: 16px;
        height: 11px;
        flex-shrink: 0;
    border-radius: 13px;
        background: #A0A0A0;
        margin: 0 0.5rem;

        background-size: contain;
        background-repeat: no-repeat;
        justify-content: center;
}
.next-btn{
    color: #FFF;
        text-align: center;
        font-family: Vodafone;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.8px;
        text-transform: uppercase;
    display: inline-flex;
        padding: 13px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    border-radius: 10px;
        background: #000;
        margin-right: 0.5rem;
        cursor: pointer;
}
.webview-div-img-holder{
    justify-content: center;
        align-items: center;
        display: flex;

}
.webview-div-holder{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.webview-div-holder-title{color: #000;
    width: 75%;
    font-family: Vodafone;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.92px;
    text-transform: uppercase;
margin-bottom: 2.5rem;}
    .webview-div-holder-description{
        width: 75%;
        color: #000;
            font-family: Vodafone;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.72px;
    }
        @media (max-width: 375px) {
        .main-page-container {
               
                height: 90vh;
               
            }
                        
        }