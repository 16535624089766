.joinInnerContainer {
    padding: 0;
    padding-top: 2rem;
    margin: 0;
    height: 76vh;
    display: flex;
    justify-content: center;

    width: 100%;
    background-image: url("../../assets/wiv/theme.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

}