.form {
  display: flex;
  border-top: 2px solid #D3D3D3;
  margin-bottom: 2%;
  
}

.input {
  border: none;
  border-radius: 0;
 
  width: 92%;
  font-size: 1.2em;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.sendButton {
 
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  width: 20%;
}