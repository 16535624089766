.catalogue-bg{
    background-image: url("../assets/showroom/ic-bg14.png");
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
        overflow: hidden;
}
.back-btn{
    position: absolute;
    top: 5%;
    left: 3%;
    display: flex;
    flex-direction: row;
    z-index: 1000000;
    width: 4%;
    align-items: center;
    
}
.main-div{
    width: 100%;
    height: 100%;
}
.btn-next {
    position: relative;
    z-index: 100000000;
}