.chart-card{
    background-color: white;
    margin: 0 3rem;
    padding: 2rem;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.chart-card-header{
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
}
.chart-size{
    min-height: 20rem;
    width: 100%;
}
.drop-down-btn,
.drop-down-btn:hover {
    margin-top: 20px;
    background-color: #e60000;
    width: 274px;
    height: 52px;
    flex-shrink: 0;
    color: #fff;

    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border: none;
    border-radius: 8px;
}
.chart-div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 2rem 0;
}
.section-header {display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5rem;
 }