.joinInnerContainer {
    padding: 0;
    padding-top: 1rem;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url("../../../assets/wiv/theme1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
}

.container-fluid {
    max-width: 100% !important;

    height: 100vh;

}

.row {
    height: 90vh;
    padding: 0;
    margin: 0;
}

.back-div {
    position: absolute;
    margin-top: 11vh;
    margin-left: 1vw;
    z-index: 100;
    cursor: pointer;
    top: 2vh;
    left: 9vw;
    display: flex;
    justify-self: flex-start;
    width: 3%;
}

.tip-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
    padding: 1vw 1vw;
    width: 100%;
}

.tip-number {
    color: #FFF;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 2vw;
    height: 2vw;
    border-radius: 6px;
    background-color: #e60000;
    margin-right: 0.5vw;


}

.tip-text {
    color: #000;

    text-align: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.section-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 9vw;
    justify-content: space-between;
}
.wiv-frame-div{
    width: 100%;
    display: flex;
        justify-content: center;
        align-items: center;
}
.summit-frame{
    width: 100%;
    border: none;
}
.summit-frame-selected{
    border: #e60000 0.5vw solid;
        width: 100%;
}
.wiv-video{
        font-size: 2vw;
position: absolute;
    width: inherit;
    width: 57%;
    background-color: white;
    /* padding: 12% 12%; */
    height: 5;
    height: 65%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wiv-video-1 {
    font-size: 2vw;
    position: absolute;
    width: inherit;
    width: 50%;
    background-color: white;
    /* padding: 12% 12%; */
    height: 5;
    height: 60%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.finalize-div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-styling:disabled {
    margin-top: 2rem;
    color: #FFF;
    width: 20vw;
        height: 10vh;
        font-size: 1.5vw;
    text-align: center;
 
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border-radius: 6px;
    background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
    background: #CCCCCC;
    border-radius: 8px;
    opacity: 1;
    border: none;
}
.btn-styling {
    margin-top: 2rem;
    color: #FFF;
width: 20vw;
    height: 10vh;
    font-size: 1.5vw;
    text-align: center;

    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border-radius: 6px;
    background: var(--Vodafone-red, #E60000);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;

}

.btn-styling:hover:enabled {
    background-color: #565555;
    text-shadow: none;
    color: white !important;
    text-decoration: none !important;
}
@media only screen and (orientation: portrait) {
    .tip-text {
            font-size: 5vw;
            margin-left: 2vw;
        }
    
        .tip-number {
            font-size: 5vw;
            width: 6vw;
            height: 3vh;
        }
    .btn-styling,.btn-styling:disabled {
            width: 30vw;
            height: 3.5vh;
            text-align: center;
            font-size: 2.5vw;
        }
    .section-div {
        padding-left: 23.5vw;
    }

    .back-div {
        position: absolute;
        margin-top: 11vh;
        margin-left: 1vw;
        z-index: 100;
        cursor: pointer;
        top: 2vh;
        left: 23.5vw;
        display: flex;
        justify-self: flex-start;
        width: 3%;
    }

    .row {
    height: 100vh;
        overflow-y: hidden;
    }

    .joinInnerContainer {

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .first-div-row {
        padding-top: 4vh;
        width: inherit;
    }}