.btn-back-envirtual-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
cursor: pointer;
}

.ddl {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.summit-photobooth-header {
    margin: 2rem 0;
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 48px/48px Vodafone Rg;
    letter-spacing: 4px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    display: flex;
    font-weight: 700;
}

.summit-photobooth-sub-header {
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 28px/28px Vodafone Rg;
    letter-spacing: 1px;
    color: #FFFFFF;

    opacity: 1;
    display: flex;
    font-weight: 400;

}

.asc3-frame {
    margin-top: 3rem;
    height: 25rem;
    width: 25rem;


    
    border-radius: 8px;
}

.asc3-video {
    position: absolute;
    border-radius: 50%;
    object-fit: cover;
    
    margin-top: 2.75%;
    width: 125px;
    height: 125px;
}

.btn-styling {
   width: 4rem;
   cursor: pointer;
}



.asc3-mainframe {
  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.summit-mainframe2 {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.mentor-card-screen-background {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("/src/assets/takephoto.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 120%;
    /* object-fit: cover; */
    min-height: 100%;
    min-width: 100vw;
    margin: 0;
    /* Set up proportionate scaling */
    /* width: 100%;
      height: auto; */

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}
.asc3-framename {
    position: absolute;
    color: white;
    font-weight: bolder;
    text-transform: capitalize;
   font-family: "Polaris" !important;
   margin-top: 20%;
font-weight: 400;
    font-size: 24px;
    line-height: 31px;
}
.main-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    width: 100%;
}
.back-btn-label {
    margin-top: 2.25rem;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}