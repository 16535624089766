.avatar-circle {
  width: 100px;
  height: 100px;
  background-color: #e60000;
  text-align: center;
  justify-content: center;
  border-radius: 50% !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  align-items: center;
}

.initials {
  position: relative;

  font-size: 50px; /* 50% of parent */
  line-height: 50px; /* 50% of parent */
  color: #fff;
  font-family: "Courier New", monospace;
  font-weight: bold;
}
