#body-row {
    margin-left: 0;
    margin-right: 0;
   position: absolute;
    z-index: 10000;

overflow: auto;
    background-color: var(--Vodafone-Black, #25282B);
    border-radius: 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
transition: all 0.5s ease-in-out;
    height: 90vh;
}

.list-group{

}
/* Sidebar sizes when expanded and expanded */
.sidebar-expanded {
    width: 230px;
}

.sidebar-collapsed {
    width: 60px;
}

/* Menu item*/
#sidebar-container .list-group a {
    height: 50px;
    color: white;
    background-color: var(--Vodafone-Black, #25282B) !important;
}

/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a {
    height: 45px;
    padding-left: 30px;
}

.sidebar-submenu {
    font-size: 0.9rem;
}

/* Separators */
.sidebar-separator-title {
    /*background-color: #333;*/
    height: 35px;
}

.sidebar-separator {
    /*background-color: #333;*/
    height: 25px;
}

.logo-separator {
    /*background-color: #333;    */
    height: 60px;
}

/* Closed submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="false"] .submenu-icon::after {
    content: " \f0d7";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 10px;
}

/* Opened submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="true"] .submenu-icon::after {
    content: " \f0da";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 10px;
}

#body-col {
    background-color: #333;
}
/* .tothebottom{
margin-top: 40%;
} */