.joinInnerContainer {
    padding: 0;
    padding-top: 1rem;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url("../../assets/wiv/theme1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;


}

.container-fluid {
    max-width: 100% !important;

    height: 100vh;

}

.row {
    height: 90vh;
    padding: 0;
    margin: 0;
}

.img-photobooth {
    width: 80%;
    height: 80%;
    float: right;
}

.main-text {
    color: #000;
    margin: 1vw 0;

    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 75%;
}

.main-text-header {
    color: #000;
    margin: 1vw 0;

    font-size: 2.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.main-text-for-or {
    color: #000;
    text-align: center;

    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-self: flex-start;
    align-self: flex-start;
    padding: 0 0.5vw 0 0.5vw;
}

.main-btns-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5vw 0 0 0;
}
.chat-room-div{
    justify-content: center;
        display: flex;
        flex-direction: column;
        height: 80vh;
}

.chat-room-div2 {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 80vh;
}

.chat-room-img-div {
    display: flex;
    width: 100%;
    justify-content: center;
}

.btn-inner-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 2vh 0;
}

.btn-inner-div-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btn,
.btn:hover {
    color: #FFF;
    width: 20vw;
    height: 10vh;
    text-align: center;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border-radius: 6px;
    background: var(--Vodafone-red, #E60000);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
}

.btn-text {
    color: #000;
    width: 20vw;
    height: 17vh;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1vh;
}

.chat-entrance-img {
    width: 100%;
    padding: 0 0 0 7%;
}

.main-divs {
    display: flex;
    justify-content: center;
    margin-top: 2.5vh;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.chat-drop-down-item:hover {
    border-radius: 6px !important;
    border: 3px #e60000 solid !important;
}

.main-text-for-or-mobile-portrait {
    display: none;
}

.main-btns-div-mobile-portrait {
    display: none;
}

.back-div {
    position: absolute;
    margin-top: 11vh;
    margin-left: 1vw;
    z-index: 100;
    cursor: pointer;
    top: 2vh;
    left: 9vw;
    display: flex;
    justify-self: flex-start;
    width: 3%;
}

.first-div-row {
    align-items: flex-start;
    width: 100%;
    padding: 0 1% 0 11% !important;
}

.arrow {
    padding-left: 2vw;
}

.joinInput {
    height: 7.5vh;
    font-size: 1.5vw;
}

@media only screen and (orientation: portrait) {
    .chat-room-div{
    height: 11vh;
    }
    .chat-room-div2{
       
            height: 70vh;
    }
    .joinInput {
        height: 4.5vh;
        font-size: 3vw;
    }

    .chat-entrance-img {
        width: 41vw;
        float: left;
        height: 16vh;
    }

    .chat-room-img-div {

        justify-content: flex-start;
    }

    .first-div-row {
        align-items: flex-start;
        flex-direction: column;
        padding: 0 3% 0 0 !important;
    }

    .back-div {
        position: absolute;
        margin-top: 11vh;
        margin-left: 1vw;
        z-index: 100;
        cursor: pointer;
        top: 2vh;
            left: 17.5vw;
        display: flex;
        justify-self: flex-start;
        width: 3%;
    }

    .main-text-for-or-mobile-portrait {
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 0 5.5vw !important;
        width: 100%;
        align-items: center;
        font-weight: bolder;
    }

    .main-btns-div-mobile-portrait {
        flex-direction: column;
        display: flex;
        align-items: flex-start;
    }

    .row {
        height: 90vh;
    }

    .joinInnerContainer {
        padding: 5vh 2.5vw 0 4rem !important;
        display: flex;
        flex-direction: column;
        /* padding-top: 14% !important; */
        justify-content: flex-start;
    }

    .img-photobooth {
        width: 41vw;
        float: left;
        height: 23vh;
    }

    .main-text-for-or,
    .btn-inner-div-2 {
        display: none;
    }

    .main-divs {
        align-items: flex-start;
        padding-left: 9vw;
    }

    .main-text {

        text-align: left;
        padding: 0;
        font-size: 3vw;
    }

    .main-text-header {


        padding: 0;
        font-size: 4vw;
    }

    .btn-text {
        font-size: 3vw;
        width: 100%;
        text-align: left;
        height: auto;
        padding-bottom: 3vw;
    }

    .btn,
    .btn:hover {
        width: 30vw;
        height: 3.5vh;
        text-align: center;
        font-size: 2.5vw;
    }

    .main-btns-div {
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-top: 2vh;
        padding-left: 1vw;
    }

    .btn-inner-div {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding-left: 4vw;
    }

    .btn-inner-div-2-mobile-portrait {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

    }

    hr.new4 {
        border: 0.0055vw solid black;
        padding: 0 2vh;
        width: inherit;
    }
}