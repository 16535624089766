.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  color: white;
  height: 60%;
  justify-content: space-between;
}

.activeContainer {
  display: flex;
  align-items: center;
  margin-top: 35px;
  overflow-y: auto;
  overflow-x: hidden;
}

.activeItem {
  display: flex;
  align-items: center;
  
}

.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .textContainer {
 margin: 0;
  }
}
@media screen and (max-width: 579px) {
  .textContainer {
    text-align: center;
  }
}
@media screen and (max-width: 579px) {
  .activeContainer {
justify-content: center;
}
}
