
.joinInnerContainer {
    padding: 0;
    padding-top: 1rem;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
background-color: #F9F3FA;
height: 100vh;
}

.container-fluid {
    max-width: 100% !important;

    height: 100vh;

}

.row {
    height: 90vh;
    padding: 0;
    margin: 0;
}
.drop-down-btn-unsubscribe,.drop-down-btn {
    position: absolute;
    margin-top: 5vh;
    margin-left: 1vw;
    z-index: 100;
    cursor: pointer;
    font-size: 1.5vw;
    top: 2vh;
        right: 11%;
    display: flex;
    justify-self: flex-start;
box-shadow: none;
    background-color: transparent;
    color: var(--Vodafone-red, #E60000);
   
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* padding-right: 1.5rem; */
    text-transform: capitalize;
   
    z-index: 100000;
    /* text-align: left; */
    text-decoration: underline;
    letter-spacing: 0.13px;
    color: #E60000;
    border: none;
    opacity: 1;
}
.back-div {
    position: absolute;
    margin-top: 5vh;
    margin-right: 1vw;
    z-index: 100;
    cursor: pointer;
    top: 2vh;
    left: 5vw;
    display: flex;
    justify-self: flex-start;
    color: #fff;
    width: 3%;
}

.header-video-text {
    color: #000;

    text-align: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.video-background {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    border: #fff 5px solid;
}

.header-video-text {
    color: #fff;

    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.clickable-part {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 49%;
    height: inherit;
    position: absolute;
    z-index: 100000;
    top: 30%;
}

.text-forstart {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.page-header {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.page-header-bold {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.stage-frame-div {
    width: 60%;
    height: 100%;
      
}

.main-session-stage {
display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
justify-content: space-evenly;
    align-items: center;
}

.play-list {
    width: 10%;
}

.video-background-small {
    height: 7.5vw;
    width: 10vw !important;
    position: absolute;


    right: 0%;

}

.video-background-smallclickable {
    height: 7.5vw;
    width: 10vw !important;
    position: absolute;
    z-index: 1000000000001;

    right: 0%;
}

.badge-iframe-completed {
    position: absolute;
    right: 1vw;
    margin-top: 3vh;
    z-index: 1000;
}
@media only screen and (orientation: landscape) {}
@media only screen and (orientation: portrait) {
.onimage-bg{
    display: none;
}
    .stage-frame-div {
    width: 90%;
        height: 60%;
            margin-left: 0;
    }

    .joinInnerContainer {
        flex-direction: column;
    }

    .badge-iframe-completed {
        font-size: 4vw;
        right: unset;
        margin-top: 0;
    }

    .video-background-small {
        height: 14.5vw;
        width: 29vw !important;

       
        position: relative;
        z-index: 10;
        /* right: 0%; */
    }

    .video-background-smallclickable {
        height: 14.5vw;
        width: 29vw !important;

        position: absolute;
        z-index: 11;
        right: unset;
        top: 1vh
        
    }

    .play-list {
        width: 80%;
     
    }

    .main-session-stage {
    
        height: 100%;
        flex-direction: column;
    }

    .clickable-part {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        width: 62%;
        height: 24vh;
        position: absolute;
        z-index: 100000;
        top: 46%;
    }
        .drop-down-btn-unsubscribe,
        .drop-down-btn {
            position: absolute;
            margin-top: 5vh;
            margin-left: 1vw;
            z-index: 100;
            cursor: pointer;
            font-size: 4.5vw;
            top: 2vh;
            right: 4%;}
}
.onimage-bg{
    position: absolute;
    z-index: 1000;
  
    /* width: 50%; */
    left: -25%;
    bottom: 0;
    pointer-events: none;
    width: 80vw;
        height: 80vh;
   
}
@media only screen and (max-width: 991.98px) {.onimage-bg {left: -15%;width: 60vw;
    height: 60vh;}}
