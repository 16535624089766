/*breakRoomListing*/
.breakRoom-container-fluid {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
  overflow-x: hidden;
}

.breakroom-listing-screen-background {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.playground-flip {
  position: relative;
  max-height: 25rem;
  /* width: 100%; */
}
.playground-front {
  margin: 2rem 10rem 15rem;
  min-height: 29rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.playground-back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}
.playground-flip > .playground-front,
.playground-flip > .playground-back {
  /* display: block; */
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 0.5s;
  transition-property: transform, opacity;
}
.playground-flip > .playground-front {
  transform: rotateY(0deg);
}
.playground-flip > .playground-back {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 29rem;
  transform: rotateY(-180deg);
}
.playground-flip:hover > .playground-front {
  transform: rotateY(180deg);
}
.playground-flip:hover > .playground-back {
  opacity: 1;
  transform: rotateY(0deg);
}

.playground-flip {
  position: relative;
  display: inline-block;
  /* margin-right: 2px; */
  /* margin-bottom: 1em; */
  /* width: 400px; */
}
.cards-flip-container {
  padding-bottom: 5rem;
  /* margin-left: -6rem; */
}
.playground-flip > .playground-front,
.playground-flip > .playground-back {
  color: white;
  width: 20rem;
  /* background-size: cover !important;
  background-position: center !important; */
  height: 29rem;
  padding: 2rem 2rem;
  background: #313131;
  border-radius: 10px;
  margin: 2rem 10rem 15rem;
}
.playground-card-button {
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  background-color: #e60000;
  color: #ffffff;
  /* font-family: "Vodafone Rg"; */
  font-weight: bold;
  line-height: 2rem;
  margin-right: 0.5rem;
}
.nav-button-padding {
  padding-right: 5.5rem;
}
.car-margin {
  margin-bottom: 7rem;
}
.playground-card-button:focus,
.playground-card-button.focus,
.playground-card-button:active,
.playground-card-button.active,
.playground-card-button:focus,
.playground-card-button.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  color: #ffffff;
  text-decoration: none;
}
.playground-card-button:hover {
  color: white;
  text-decoration: none;
}
.mentors-header-page-title {
  color: white;
}
