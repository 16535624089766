.btn-outline {
    margin: 2rem 2rem;
    padding: 0.5rem 0.5rem;
    width: 10rem;
    z-index: 10000;
    text-align: center;
    font: normal normal normal 16px/18px Vodafone Rg;
    letter-spacing: 0px;
    color: #e60000;
    opacity: 1;
    border: 1px solid #e60000;
    border-radius: 8px;
    opacity: 1;
}

.btn-outline:hover {
    text-decoration: none;
    color: #e60000;
}

.main-postKeynotes-page-bg {
    background: transparent linear-gradient(113deg, #ffffff 0%, #ffffff39 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #ffffff;
    border-radius: 16px;
    opacity: 1;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    height: 100%;
}

.main-postKeynotes-page-header {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 1.7rem;

    opacity: 1;
}

.main-postKeynotes-page-button {
    background: #ebebeb 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    padding: 0.25rem 0.25rem;
    margin-right: 0.25rem;
    width: 4rem;
    margin-bottom: 0.5rem;
    border: none !important;
}

.btn-selcted {
    background: #E60000;
        border-radius: 6px;
    opacity: 1;
    color: #ebebeb;
    padding: 0.25rem 0.25rem;
    margin-right: 0.25rem;
    width: 4rem;
    margin-bottom: 0.5rem;
    border: none !important;
}

.main-postkeynote-header {
    display: "flex";
    flex-direction: "row";
}

.post-keynote-header {
    padding: 0.5rem 0.5rem;
    position: absolute;
    font: normal normal normal 56px/64px Vodafone Rg;
    letter-spacing: 0px;
    color: #e60000;
    opacity: 1;
}

.carddimensions {
background: linear-gradient(180deg, rgba(255, 255, 255, 0.46) -202.19%, rgba(255, 255, 255, 0.15) 100%);
    box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2.5px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;
    padding: 2rem 2rem;
    margin: 1.25rem 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-content{
        display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 15rem;
}

.card-text {
font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    color: #FCFCFC;
}

.small-download-cards {
background: #ebebeb 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    padding: 0.25rem 0.25rem;
    margin-right: 0.25rem;
    width: 1.5rem;
    margin-bottom: 0.5rem;
    border: none !important;
    cursor: pointer;
    position: absolute;
        top: 9%;
        right: 9%;
}
.table-small-download-cards {
    background: #ebebeb 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    padding: 0.25rem 0.25rem;
    margin-right: 0.25rem;
    width: 1.5rem;
    margin-bottom: 0.5rem;
    border: none !important;
    cursor: pointer;
   
}

.t-width {
    margin: 0.5rem 2.5rem;
}.mentor-card-screen-background {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("/src/assets/asc-3-bg.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 120%;
    /* object-fit: cover; */
    min-height: 100vh;
    min-width: 98vw;
    margin: 0;
    /* Set up proportionate scaling */
    /* width: 100%;
      height: auto; */

    /* Set up positioning */
   overflow-x: hidden;
}
.mask {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("/src/assets/octagons.svg");

    background-position: center;
    background-repeat: repeat;
    
    /* object-fit: cover; */
    min-height: 100%;
    min-width: 100vw;
    margin: 0;
    /* Set up proportionate scaling */
    /* width: 100%;
      height: auto; */

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}
.btn-back-envirtual-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;

}

.ddl {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.back-btn-label {
    margin-top: 2.25rem;
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #FCFCFC;
}
.table-bg{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.46) -202.19%, rgba(255, 255, 255, 0.15) 100%);
        box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2.5px);
        /* Note: backdrop-filter has minimal browser support */
    
        border-radius: 8px;
        max-width: 100%;
        margin: 1rem 1.75rem;
        overflow-y: auto;
        max-height: 70vh;
}
.table-cells{
    align-items: flex-start;
        justify-content: flex-start;
        display: flex;
}
.table-header{
    border-bottom: 0.5px solid rgba(252, 252, 252, 0.6);
    
       
}