.container-fluid {
    max-width: 100% !important;

    height: 100vh;

}

.row {
    height: 90vh;
    padding: 0;
    margin: 0;
}
.wiv-joinOuterContainer{
    padding: 0;
        padding-top: 1rem;
        margin: 0;
    
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        background-image: url("../../assets/wiv/theme1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
height: 100vh;
        position: absolute;
        z-index: 100000000000;
        flex-direction: column;
}
.header-div{
    width: 100%;
    padding-top: 1vh;
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;
    padding-right: 2vw;
}
.main-text-bold {
    color: #000;

    text-align: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.main-text {
    color: #000;

    text-align: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.loading-bar-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.skipDiv{
    cursor: pointer;
}
.sub-main-text {
    color: #e60000;
padding: 2vh 0 2vh 0;
    text-align: center;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.wiv-tip-text-bolder {
    color: #E60000;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.wiv-tip-text {
    padding: 1vh 0;
    color: #000;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
     text-align: center;
}
.footer-text{
    padding: 1vh 0;
        color: #000;
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
}
.footer-bold-main-text {
    color: #000;

    text-align: center;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.first-section{
    align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 2px solid #FCFCFC;
        box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2.5px);
        /* Note: backdrop-filter has minimal browser support */
    
        border-radius: 10px;
    
        padding: 3vh 1vw;
        margin: 2vh 0;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35%;
}
.img {
    z-index: 9999;
    width: 10vw;
    height: 15vh;

}
.other-section {
    display: none;
    align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 2px solid #FCFCFC;
        box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2.5px);
        /* Note: backdrop-filter has minimal browser support */
    
        border-radius: 10px;
    
        padding: 3vh 1vw;
        margin: 2vh 0;
        flex-direction: column;
      
        justify-content: center;
        align-items: center;
        width: 35%;

}
.loading-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: 1vh;
}
.iframe{
    border: none;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
       
        display: block;
        /* Add this */
  
}
@media only screen and (orientation: portrait) {
    .loading-bar-div{
            justify-content: space-evenly;
            height: 30%;
    }
    .first-section {width: 80%;}
.img {
    z-index: 9999;
    width: 10vh;
    height: 15vw;

}
        .main-text {
         
            font-size: 3vh;
        
        }
    
    
        .sub-main-text {
            padding: 3vw 0 3vw 0;
            font-size: 2.5vh;
            
        }
    
        .wiv-tip-text-bolder {
           
            font-size: 2.25vh;
          
        }
    
        .wiv-tip-text {
            padding: 1vw 0;
          
            font-size: 2.25vh;
          
        }
    
        .footer-text {
            padding: 1vw 1vw;
            color: #000;
            font-size: 2vh;
      
        }
    
        .footer-bold-main-text {
          
            font-size: 2vh;
        
        }
                .iframe {padding-left: 7%;}
}
