.bs-stepper {
  border: white 1px solid;

  border-radius: 30px;
  padding: 2rem;
}
.active .bs-stepper-circle,
.bs-stepper-label:active {
  background-color: red;
}
.bs-stepper .step-trigger {
  color: white;
}
.bs-stepper .step-trigger:focus {
  color: white;
}
label,
h4 {
  color: white;
}
