.wiv-modal-header {
    padding-top: 2rem;
    color: #000;

    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.wiv-tips-to-navigate {
    color: var(--Vodafone-red, #E60000);
    font-family: Rubik;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.wiv-inner-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wiv-tip {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    align-items: center;

}
.wiv-mobile-tip {display: flex;
    flex-direction: column;
    padding-top: 1rem;
    align-items: center;
justify-content: center;}

.wiv-tip svg,.wiv-mobile-tip img {
    width: 3.5rem;
}
.wiv-mobile-tip img {width: 3.5rem; margin: 1rem 0;}
.wiv-tip-text {
    color: #000;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 75%;
    padding-left: 1rem;
}
.wiv-tip-mobile-text {
    color: #000;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 75%;
    padding: 1rem 1rem 0 0;
    text-align: center;
}
.wiv-btn {
    margin-top: 2rem;
    width: 253px;
    height: 60px;
    flex-shrink: 0;
    background: #FFF;

    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.30);
    text-align: center;
    border: #E60000 1px solid;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Vodafone-red, #E60000);
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    cursor: pointer;
}

.wiv-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wiv-tip-text-bolder {
    color: #E60000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.wiv-tip-mobile-text-bolder {
    color: #E60000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1rem;
}
@media only screen and (max-width: 991.98px) {
    .wiv-tip-text-bolder,.wiv-tip-text,.wiv-tip-mobile-text,.wiv-tip-mobile-text-bolder {font-size: 12px;}.wiv-modal{
    z-index: 10000000;
    position: absolute;
}
.wiv-modal-header{font-size:20px ;padding-top: 3rem;}
.wiv-tip svg {width: 2.5rem}
#wivawelcome{
    position: absolute !important;
        z-index: 10001 !important;
}}