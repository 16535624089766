.joinInnerContainer {
    padding: 0;
    padding-top: 1rem;
    margin: 0;

    display: flex;
 
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url("../../assets/wiv/theme1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
}

.container-fluid {
    max-width: 100% !important;

    height: 100vh;

}

.row {
    height: 90vh;
    padding: 0;
    margin: 0;
}
.back-div {
    position: absolute;
    margin-top: 11vh;
    margin-left: 1vw;
    z-index: 100;
    cursor: pointer;
    top: 2vh;
    left: 9vw;
    display: flex;
    justify-self: flex-start;
    width: 3%;
}
.main-text{
    color: #000;
    
        text-align: center;
        font-size: 2vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
}
.mosaicwall-room-div{
height: 75%;
    display: flex;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url("../../assets/wiv/mosaicwall.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
max-width: 88%;
}
.images-div {
    overflow-y: auto;
    padding: 0;
    height: 50vh;
    max-width: 45%;
}
.img {
    width: 5vw;
        height: 11vh;
}
@media only screen and (max-width: 991.98px) {
    .mosaicwall-room-div {max-width: 88%};
        .images-div {max-width: 88%;}
}
@media only screen and (orientation: portrait) {
    .img {
            width: 10vw;
            height: 4vh;
        }
    .images-div {
            overflow-y: auto;
            padding: 0;
            height: 18vh;
            max-width: 100%;
        }
    .mosaicwall-room-div {
        height: 50%;
            width: 77%;
            padding: 0 14vw;
            margin: 0 19vw;}
    .border-right {
        border: none;
    }

    .section-div {
        padding-left: 23.5vw;
    }

    .back-div {
        position: absolute;
        margin-top: 11vh;
        margin-left: 1vw;
        z-index: 100;
        cursor: pointer;
        top: 2vh;
        left: 23.5vw;
        display: flex;
        justify-self: flex-start;
        width: 3%;
    }

    .row {
        height: 90vh;
    }

    .joinInnerContainer {

        display: flex;
        flex-direction: column;
            justify-content: center;
    }}