.general-chat {
  background-image: url("../../../../assets/background.png");

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  /* border-radius: 8px; */
  height: 100vh;
}
